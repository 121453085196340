import type { Editor } from "@tiptap/react";
import { NodeViewWrapper } from "@tiptap/react";
import classNames from "classnames";
import { CTAOptions } from "./CTAOptions";

interface RendererProps {
  editor: Editor;
  node: any;
  updateAttributes: any;
  selected: boolean;
  deleteNode: () => void;
}

export const Renderer = ({
  editor,
  node,
  updateAttributes,
  deleteNode,
  selected,
}: RendererProps) => {
  const isEditable = editor.isEditable;

  return (
    <NodeViewWrapper
      as="div"
      className={classNames(
        "group relative my-2 flex items-center rounded-lg border py-4",
        {
          "justify-center": node.attrs.alignment === "center",
          "justify-start": node.attrs.alignment === "left",
          "justify-end": node.attrs.alignment === "right",
        },
        {
          "border-transparent": !isEditable,
          "hover:border-dark border-transparent": isEditable && !selected,
          "border-circle": isEditable && selected,
        },
      )}
    >
      <a
        style={{
          backgroundColor: node.attrs.color,
        }}
        className={classNames(
          "tiptap-cta group relative block rounded-lg px-6 py-2.5 font-medium !text-white mix-blend-difference",
          node.attrs.full_width ? "w-full text-center" : "w-auto",
        )}
        target="_blank"
        href={node.attrs.url}
        rel="noreferrer"
      >
        {node.attrs.label}
      </a>
      {isEditable && (
        <CTAOptions
          editor={editor}
          node={node}
          updateAttributes={updateAttributes}
          deleteNode={deleteNode}
        />
      )}
    </NodeViewWrapper>
  );
};
